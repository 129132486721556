.deliveryPage {
	margin: .75rem;
	padding: .75rem;
	border: solid 1px black;
	max-width: 420px;
	border-radius: 5px;
	box-shadow: 2px 2px 4px rgba(181, 178, 178, 0.5);
	display: flex;
	flex-direction: column;
	align-content: space-between;
	row-gap: 1.25rem;
	align-self: center;
	width: 86%;
}

.deliveryPage h2 {
	align-self: center;
}

.flexRow {
	display: flex;
	justify-content: space-between;
	align-items: center;

}

.dateButtons {
	margin-top: 10%;
}

.errorRow {
	display: flex;
	justify-content: end;
}

.errorRow:empty {
	display: none;
}


.error {
	color: red;
	margin: 0;
	width: 50%;
}

.slotError {
	color: red;
}

.deliveryPage select,
.deliveryPage button,
.flexRow label,
.flexRow input,
.flexRow select,
.flexRow button {
	font-size: 1.2rem;
	border-radius: 5px;
	box-sizing: border-box;
}

.flexRow label,
.flexRow input,
.flexRow select,
.flexRow button {
	width: 48%;
}

.day {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: .5rem;
	row-gap: .5rem;
	border: 2px solid rgb(240, 240, 240);
	box-shadow: 1px 1px 6px rgba(96, 95, 95, 0.708);
	cursor: pointer;
}

.day:hover {
	box-shadow: 1px 1px 9px rgba(96, 95, 95, 0.708);

}


.flexRow input,
.flexRow select {
	text-align: center;
	width: 50%;
	margin: 0;
}



.wideBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .5rem;
	height: 2.5rem;
	width: 100%;
	font-size: 1.2rem;
	border-radius: 5px;
	box-sizing: border-box;
}

.wideBtn:hover {
	box-shadow: 1px 2px 2px rgb(208, 196, 196);
	cursor: pointer;
}

.selected {
	border: 2px solid #82924B;
	box-shadow: 1px 1px 3px rgba(96, 95, 95, 0.089);
	color: #82924B;
}

.selected:hover {
	box-shadow: 1px 1px 3px rgba(96, 95, 95, 0.089);

}

.centered {
	text-align: center;
}

/* 
.morningHours {
	color: #258faf;
}

.eveningHours {
	color: rgb(79, 13, 79);
} */

@media (max-width: 550px) {
	.error {
		width: 70%;
	}
}

@media (min-width: 1024px) {}