.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 90vh;
}

p.loginText {
	font-size: 1.5rem;
}

button.signInButton {
	height: 3rem;
	width: 12rem;
	font-size: 1rem;
}