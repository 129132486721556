.menuItem {
  text-decoration: none;
  display: flex;
  border-radius: 5px;
  margin: 3% 0;
  width: 90%;
  box-shadow: 1px 1px 4px rgba(81, 91, 47, .4);
  color: black;
}

.menuItem:hover {
  box-shadow: 1px 1px 4px rgba(81, 91, 47, .8);
}

.menuItem.inactiveItem {
  opacity: 0.5;
  pointer-events: none;
  color: #a0a0a0;
  background-color: #f0f0f0;
  display: none;
}

.menuPhoto {
  height: 14vh;
  width: 14vh;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
}

.textBox {
  flex-grow: 2;
  font-size: 1rem;
  padding: 0 .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .5rem 0;
}

.textBox h2 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  text-align: center;
}

.textBox h4 {
  margin: 0;
}

.stockMessage {
  font-weight: normal;
  margin: 0;
}

.outOfStock {
  color: red;
}

.lowStock {
  color: green;
}

@media (min-width: 550px) {
  .menuItem {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .menuItem {
    width: 30%;
    margin: .75rem;

  }

  .menuPhoto {

    height: 8rem;
    width: 8rem;
  }

  .textBox {
    padding-left: .5rem;
  }
}