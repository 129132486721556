.form {
  margin: 0 5%;
  display: flex;
  flex-direction: column;
}

.form input,
.form textarea {
  margin: 5px 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  /* Ensures padding and border are included in the element's width */
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: vertical;
  /* Allows vertical resizing, but prevents horizontal resizing */
  height: 100px;
  /* Adjust the height as needed */
}

.form button {
  margin-top: 10px;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
}