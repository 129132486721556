.slide {
	padding: 2rem .75rem;
	background: #D3DBBA;
	padding-bottom: 1rem;
}

.slide h2 {
	font-size: 1.5rem;
	text-align: start;
	font-weight: 500;
	margin: 0;
}

.contact {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}



.phoneLine {
	display: flex;
	column-gap: 2%;
	align-items: center;
	margin: 0;
}

.phoneLine a {
	font-size: 1.1rem;
	color: black;
	text-decoration: underline;
	margin: 0;
}

.phoneLine p {
	font-size: 1.1rem;
	margin: 0;
}

.emailLine {
	display: flex;
	flex-wrap: wrap;
	column-gap: 2%;
	align-items: center;
	margin: 0;
}

.emailLine a {
	font-size: 1.1rem;
	color: black;
	text-decoration: underline;
}

.emailLine p {
	font-size: 1.1rem;
	margin: .1rem 0;
}

.instagramLine {
	display: flex;

}

.instagramLine {
	font-size: 1.1rem;
}

.icon_container {
	text-align: center;
	margin-left: 1rem;
	display: flex;
}

.icon_container p {
	color: black;
	text-decoration: none;
}

.instagramIcon {
	width: 3rem;
	height: 3rem;
	margin: 0;
}


@media (min-width: 550px) {

	.slide h2 {
		font-size: 5vw;
	}

	.phoneLine a {
		font-size: 4vw;
	}

	.hours h4,
	.hours p {
		font-size: 4vw;
	}
}

@media (min-width: 1024px) {

	.slide {
		display: flex;
		flex-direction: column;
		row-gap: 5vw;
		align-items: flex-start;
	}

	.slide h2 {
		font-size: 2rem;
		margin-bottom: 0;
	}

	.contact {
		display: flex;
		flex-direction: row;
		row-gap: 1vw;
		width: 100%;
		justify-content: space-around;
	}

	.contact div {
		display: flex;
		flex-direction: column;
	}

	.phoneLine {
		display: flex;
		margin: 0;

	}

	.hours {
		display: flex;
		flex-direction: column;
		margin: 0;
		row-gap: 1vw;
	}

	.phoneLine a {
		font-size: 1.25rem;
	}

	.hours h4,
	.hours p {
		font-size: 1.25rem;
		margin: 0;
	}

}