.welcomeSlide {
	margin: 4rem 0;
	display: flex;
	flex-direction: column;
}

.welcomeSlide p {
	margin: 1rem;
}

.welcomeSlide .header {
	font-size: 36px;
	opacity: 0;
	animation: fadeIn 2s .4s forwards;
	color: rgb(43, 97, 221);
}

.welcomeSlide .sub_header {
	opacity: 0;
	animation: fadeIn 2.5s 0s forwards;
	font-size: 21px;
}

.sub_header strong {
	color: rgb(14, 99, 14);

}

.logo {
	width: 8rem;
	align-self: center;
	margin: 1rem;
	transition: transform 0.3s ease-in-out;
}


@keyframes fadeIn {
	to {
		opacity: 1;
	}
}

@media (min-width: 760px) {
	.welcomeSlide {
		text-align: center;
	}

	.logo {
		width: unset;
	}
}

@media (hover: hover) {
	.logo:hover {
		transform: scale(1.05);
		cursor: pointer;
	}

	.logo.no_hover:hover {
		transform: none;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.spin {
	animation: spin 0.6s ease-in-out;
}