.heroSlide {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 8rem 1rem;
	background-image: url('../../../../public/cafe_2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  z-index: 1; 
}

.heroSlide h1 {
	color: rgb(255, 255, 255);
	font-size: 1.9rem;
  margin: 0 auto;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.textContainer {
  z-index: 2; /* Ensure content is on top of the overlay */
	text-align: center;
}

.buttonContainer {
  position: absolute;
  bottom: -20px; 
  left: 50%;
  transform: translateX(-50%);
  width: 100%; 
  display: flex;
	align-items: flex-end;
  justify-content: center;
  z-index: 1; 
}

.heroButton {
	border: 1px solid rgb(129, 155, 94);
	display: flex;
	font-size: 1.25rem;
	font-weight: 500;
  padding: 1.5% 3%;
  border-radius: 8px;
  color: rgb(88, 95, 86);
	background: rgb(255, 255, 255);
	width: 45%;
	justify-content: space-between;
	align-items: center;
	box-shadow: 1px 1px 3px rgb(129, 155, 94);
	text-decoration: none;
	padding: .75rem 2rem;
    
    
}

.heroArrow {
	transform: translateY(1px);
	stroke-width: 2.5px;
}

.changingWord {
	transition: opacity 1.5s;
}

.fadeIn {
	opacity: 1;
}

.fadeOut {
	opacity: 0;
}

@media (min-width: 550px) {

	.heroButton {
		font-size: 4vw;
	}

	.heroButton {
		width: 17rem;
	}
}

@media (min-width: 1024px) {

	.heroSlide {
		height: 30vw;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.heroSlide h1 {
		font-size: 3.5rem;
		width: 60%;
		margin: auto 5%;
		text-align: left;
	}

	.heroButton {
		font-size: 2.5vw;
		height: 2rem;
		width: 20%;
		padding: 1rem;
		box-shadow: 2px 2px 6px rgb(129, 155, 94);
	}

	.heroButton:hover {
		box-shadow: 3px 3px 8px rgb(129, 155, 94);
	}

	
}

