.aboutPage {
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(to bottom, #F8F9F3, #F0F3E8, #E9EDDC);
}

.aboutPage h1 {
	text-align: center;
}

.aboutPage p {
	font-size: 1.2rem;
	line-height: 1.4;
	padding: 5%;
	margin: 0;
	max-width: 600px;
}

.aboutPage p strong {
	font-weight: 600;
}

.aboutPage p:nth-child(2) {
	margin-top: 0;
}

p.disclaimer {
	align-self: flex-start;
	font-size: 1rem;
}

p.small {
	font-size: 1rem;
	padding-bottom: 1%;
}

@media (min-width: 550px) {

	.aboutPage p {
		padding: 1rem 14vw;
	}

}

@media (min-width: 1024px) {


	.aboutPage p {
		font-size: 1.2rem;
		padding: 5%;
		margin: 0;
	}

	.paragraphs {
		display: flex;
		flex-direction: column;
	}

	p.disclaimer {
		align-self: start;
		font-size: 1rem;
	}


}