.ordersContainer {
  padding: 10px;
}

.orders {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0 10px;
}

.order {
  visibility: visible;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 5px;
  margin: 5% 0;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
}

.items {
  padding-inline-start: 15px;
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}

.item {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  align-items: start;
  margin: 5% 0;
  flex-direction: row;
}


.item .description p,
.item .quantity p {
  margin: 5px;
}

.ordersTable {
  width: 100%;
  border-collapse: collapse;
}

.ordersTable th,
.ordersTable td {
  border: 1px solid grey;
  padding: 8px;
  text-align: left;
  vertical-align: top;
  /* Align the content at the top */
}


.options {
  list-style-type: none;
  padding-inline-start: 15px;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

th,
td {
  font-size: 20px;
}

.items_header {
  display: flex;
  justify-content: space-between;
}

.title .qty {
  font-weight: 600;
}

.title {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.description {
  flex-grow: 1;
}

@media (min-width: 760px) {
  .orders {
    display: none;
  }

  .ordersTable {
    display: table;
  }

  .title .qty {
    margin-left: 20px;
  }

  .ordersTable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .ordersTable tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }

}

@media (max-width: 759px) {
  .orders {
    display: block;
  }

  .ordersTable {
    display: none;
  }
}