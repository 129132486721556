.itemsColumn {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin: 0 5%;
}

.date {
  margin: 0;
  margin-bottom: 1rem;
}

.flexRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
}

.flexRow .quantity {
  text-align: end;
}

.flexRow input {
  width: 15%;
}

.list {
  list-style: none;
}

.list .flexRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.list .flexRow p {
  margin: 6% 0;
}

.saveButton {
  visibility: hidden;
  margin: 0;
}

.visible {
  visibility: visible;
}

input {
  margin-right: 0.5rem;
}
