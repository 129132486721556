.featured {
	opacity: 0;
	transition: opacity 1s ease-in;
}

.featured.visible {
	opacity: 1;
}

.slide_centered {
	display: flex;
	flex-direction: column;
	padding: 3rem .75rem;
	align-items: center;
	row-gap: 24px;
}



.slide_centered h2 {
	color: rgb(129, 155, 94);
	margin-bottom: 1rem;
	font-size: 9vw;

}

.featured_images {
	display: flex;
	flex-direction: column;
	position: relative;
	row-gap: 2rem;
}

.button_full {
	border: 1px solid black;
	padding: 1.5% 5%;
	border-radius: 8px;
	color: black;
	background: white;
	width: 50%;
	text-align: center;
	box-shadow: 1px 1px 6px rgba(129, 155, 94, .4);
	margin: 0;
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	text-decoration: none;
}

.button_full:hover {
	box-shadow: 1px 1px 6px rgb(129, 155, 94);
}

.serving {
	font-size: 21px;
	margin: 2rem auto;
	margin-top: 0;
}

.header {
	font-size: 21px;
}

.button_header {
	font-size: 21px;
	margin: 2rem 0;
	margin-bottom: 0;
}

@media (min-width: 550px) {
	.slide_centered h2 {
		color: rgb(129, 155, 94);
		margin-bottom: 10%;
		font-size: 8vw;
	}
}

@media (min-width: 1024px) {

	.slide_centered {
		padding: 5%;
		row-gap: 4vw;
	}


	.slide_centered h2 {
		font-size: 2.5rem;
		margin-bottom: 1rem;
	}

	.featured_images {
		width: 100%;
		display: flex;
		flex-direction: row;
		column-gap: 4%;
		justify-content: center;
	}

	.button_full {
		width: 20%;
		padding: 1%;
		margin: 0;
	}

	.serving {
		font-size: 2rem;
		font-weight: 500;
	}
}