.confirmationCard {
	display: flex;
	flex-direction: column;
	box-shadow: 1px 1px 3px black;
	padding: 1em;
	margin: .75em;
	border-radius: 5px;
	max-width: 500px;
	align-self: center;
	row-gap: 2rem;
}

.confirmationCard p {

}

.rows {
  display: flex;
  flex-direction: column;
  
}

.orderDetailsRow {
	display: flex;
	justify-content: space-between;
	margin: 3% 0;
  column-gap: 5%;
  text-align: right;
}

.orderDetailsRow .label {
  text-align: start;
}

.orderTable {
  border-collapse: collapse;
  margin: 0;
}

.orderTable th, .orderTable td {
  border: 1px solid black;
  padding: 6px;
  text-align: left;
}

.orderTable th {
  background-color: #f2f2f2;
}

.itemDescription {
  border-right: 2px solid black;
}

.price {
  text-align: right;
  vertical-align: bottom;
}

.optionRow {
  display: flex;
  justify-content: space-between;
}

.optionPrice {
  margin-left: auto;
}

tfoot {
  font-weight: bold;
}


.orderDetailsRow span {

}

h2 {
	margin: 0;
	margin-bottom: .5em;
}

.animatedImage {
	height: 4em;
	width: 4em;
  /* transition: transform 1s ease-in; */
	animation: flyRight 1.8s ease-in forwards; /* Adjust duration as needed */

}

.skeleton {
  display: inline-block;
  height: 1em;
  width: 100px; /* Adjust the width as needed */
  background: #e0e0e0;
  border-radius: 4px;
  animation: shimmer 1s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}



@keyframes flyRight {
  0% {
    transform: translateX(-10vw) translateY(0);
  }
  100% {
    transform: translateX(100vw) translateY(20%);
  }
}
