.checkoutForm {
  margin: 1rem;
  max-width: 800px;
  align-self: center;
  text-align: center;
}

.payButton {
  margin-top: 1rem;
  width: 70%;
  border: 1px solid -apple-system-blue;
  border-radius: 5px;
  padding: .4rem;
  font-size: 1.25rem;
  box-shadow: 2px 2px 3px rgba(57, 56, 56, .3);
}

.payButton:hover {
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(57, 56, 56, 0.587);
}

.message {
  text-align: center;
  color: rgb(0, 0, 0);
}

.soldOutItems,
.error {
  color: rgb(239, 90, 60);
  text-align: center;
}

.stripeElement {
  margin-bottom: 1rem;
}

@media (min-width: 780px) {
  .message {
    max-width: 500px;
    align-self: center;
    line-height: 1.75rem;
  }
}