.arrow {
	width: 12vw;
	height: 8vw;
	border: 1px solid black;
	border-radius: 5px;
	background: #f0f0eb;
	margin: 2vw;
	position: sticky;
	top: 65px;
	/* z-index: 1000; */
}

@media (min-width: 1024px) {
	.arrow {

		width: 3rem;
		height: 2rem;
		margin: .5rem;
		top: 5px;
	}


}