.offering {
	text-align: center;
	margin: 5%;
	color: rgb(5, 103, 31);
	font-weight: 500;
	margin-bottom: 0;
}

.minimizedButton {
	width: 100%;
	font-size: 1.2rem;
	background-color: #ebefe1;
	color: rgb(5, 103, 31);
	text-align: center;
}

.deliveryDateSelection {
	display: flex;
	flex-direction: column;
	margin: 1rem;
	background: #ebefe1;
}



.menu {
	padding: 4%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.deliveryPage h2 {
	align-self: center;
}

.flexRow {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 1rem;
	max-width: 500px;
	background-color: #ebefe1;
}


.ordersMessage {
	margin: 0 3rem;
	font-size: 1rem;
}

.loadingMessage {
	text-align: center;
}

.deliveryPage select,
.deliveryPage button,
.flexRow label,
.flexRow input,
.flexRow button {
	font-size: 1.2rem;
	border-radius: 5px;
	box-sizing: border-box;
}

.flexRow label,
.flexRow input {
	width: 48%;
}

.day {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: .5rem;
	box-shadow: 1px 1px 6px rgba(96, 95, 95, 0.708);
	cursor: pointer;
	background-color: lightgray;
	color: black;
	width: 40%;
}

.day:hover {
	box-shadow: 1px 1px 9px rgba(96, 95, 95, 0.708);

}

.flexRow input {
	text-align: center;
}

.selected {
	border: 2px solid #82924B;
	box-shadow: 1px 1px 3px rgba(96, 95, 95, 0.089);
	color: #82924B;
}

.selected:hover {
	box-shadow: 1px 1px 3px rgba(96, 95, 95, 0.089);

}

.loadingContainer {
	text-align: center;
}

.loadingContainer p {
	font-size: 1.25rem;
}

@media (min-width: 780px) {
	.deliveryDateSelection {
		display: flex;
		flex-direction: column;
		width: 30%;
		justify-content: center;
		align-items: center;
		align-self: center;
		cursor: pointer;
	}

	.minimizedButton {
		margin: .75rem;
	}


	.flexRow {
		align-self: center;
		column-gap: 1rem;
		padding: 0;
		padding-bottom: 1rem;
	}

	.flexRow button {
		flex-grow: 2;
	}

	.ordersMessage {
		text-align: center;
		margin: 1rem 0;
	}

}

@media (min-width: 1024px) {
	.menu {
		padding: 0;
	}

}