.stepsSlide {
	display: flex;
	flex-direction: column;
	background: linear-gradient(to top left, rgb(249, 249, 249), #D3DBBA);
	padding: 2rem 1rem;
	row-gap: 4rem;
	transition: opacity 0.25s ease-in;
	padding: 5rem 0;
}

.stepsSlide .header {
	font-size: 21px;
	text-align: center;
	font-weight: 600;
	margin: 0;
}

.steps {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	align-items: center;
}

.steps div {
	display: flex;
	border: 1px solid grey;
	margin: 0;
	column-gap: 16px;
	align-items: center;
	background-color: #f8f8f8;
	padding: 4px 8px;
	border-radius: 5px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
	opacity: 0;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.steps.isVisible .step1,
.steps.isVisible .step2,
.steps.isVisible .step3,
.steps.isVisible .step4 {
	opacity: 1;
}

.step {
	width: 90%;
}

.step1 {
	animation: slideIn 1s 0s forwards;
}

.step2 {
	animation: slideIn 1s .5s forwards;
}

.step3 {
	animation: slideIn 1s 1s forwards;
}

.step4 {
	animation: slideIn 1s 1.5s forwards;
}

.icon {
	width: 48px;
	height: 48px;
	stroke-width: 1px;
}

.icon path {
	stroke-dasharray: 320;
	stroke-dashoffset: 0;
	animation: dash 4s ease-in 0s infinite alternate-reverse both;
}

@keyframes slideIn {
	from {
		opacity: 0;
		transform: translateX(-100vw);
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes dash {
	from {
		stroke-dashoffset: 320;
	}

	to {
		stroke-dashoffset: 0;
	}
}