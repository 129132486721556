.carousel {
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  justify-content: center;
  position: relative;
}

.carousel::before {
  content: '';
  flex: 0 0 50vw;
}

.carousel::after {
  content: '';
  flex: 0 0 50vw;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carouselItem {
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(229, 228, 228);
  margin: 25px;
  box-sizing: border-box;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 1px 1px 5px grey;
}

.carouselItem img,
.carouselItem video {
  width: 15rem;
  height: auto;
  border-radius: 5px;
  margin: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.carouselItem .itemTitle {
  margin: 5px;
  font-size: 21px;
}

.carouselItem.active {
  transform: scale(1.1);
}

@media (min-width: 760px) {

  .carousel {
    overflow-x: hidden;
  }

  .carouselItem {
    box-shadow: none;
  }

  .carouselItem.active {
    transform: scale(1);

  }

  .carouselItem:hover {
    transform: scale(1.05);
  }
}