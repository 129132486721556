.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 90vh;
}

p.UnauthorizedPage {
	font-size: 1.5rem;
	text-align: center;
}