.dashboardNav {
	display: flex;
	flex-direction: column;
	margin-bottom: 5%;
	max-width: 600px;
}

.dashboardMenu {
	display: flex;
	justify-content: space-around;
	list-style-type: none;
	padding-inline-start: 0;
	padding: 2%;
	margin: 3%;
	font-size: large;
}

.dashboardMenu li a {
	text-decoration: none;
	color: black;
}

.activeLink {
	font-weight: 600;
}

.date {
	margin: 0 5%;
	width: 90vw;
	align-self: center;
	font-size: large;
	max-width: 600px;
}

