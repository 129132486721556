.expandedContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.menuItemExpanded {
  margin: 2%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 5px;
  width: 80%;
  align-self: center;
  padding-bottom: .75rem;
}

.title {
  margin-bottom: 0;
  font-weight: 600;
  margin: 2%;
  margin-bottom: 4%;
  align-self: center;
  text-align: center;
}

img {
  border-radius: 15px;
  margin: 2vw;
  width: 60%;
  align-self: center;
}

.description {
  font-size: 5vw;
  margin: 2vw;
  margin-bottom: 2rem;
  white-space: pre-line;
}

.optionsList {
  display: flex;
  flex-direction: column;
  font-size: 5vw;
  margin: .5rem;
  row-gap: 1rem;
}

.optionGroup {
  display: flex;
  flex-direction: column;
}

.optionGroup h3 {
  margin: 1% 0;
  font-size: 1.25rem;
}

.optionRow {
  display: flex;
  justify-content: space-between;
  margin: 3vw 0;
  column-gap: 3%;
}

.optionRow p {
  margin: 0;
}

.checkbox_and_label {
  display: flex;
  align-items: center;
  flex-grow: 2;
}

.checkbox_and_label input[type="checkbox"] {
  width: 7vw;
  height: 7vw;
  margin: 0;
  margin-right: 3%;
}

.footer {
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
  background: white;
  padding: 1% 0;
  border-top: 1px solid black;
  padding-top: .75rem;
}

.footer select,
.footer button {
  font-size: 5.5vw;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #616E38;
  color: #616E38;
  margin: 2vw;
  -webkit-filter: drop-shadow(1px 1px 2px rgb(155, 148, 148, .5));
}

.footer .addToCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
  text-align: center;
  padding: 2%;
  cursor: pointer;
}

.footer .addToCart.centerText {
  justify-content: center;
}

.footer .addToCart:hover {
  box-shadow: 1.4px 1.6px 3px rgba(128, 128, 128, 0.498);
}

.footer .addToCart.unavailable {
  color: red;
  cursor: default;
}

.footer .addToCart.unavailable:hover {
  box-shadow: none;
}

.footer .addToCart[disabled] {
  cursor: not-allowed;
}

.footer .addToCart[disabled]:hover {
  box-shadow: none;
}

.checkoutButton {
  border: 1px solid black;
  width: 60%;
  align-self: center;
  border-radius: 10px;
  padding: .5rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #616E38;
  font-size: 5.5vw;
}

.checkoutButton:hover {
  box-shadow: 1.4px 1.6px 3px rgba(128, 128, 128, 0.498);
}

@media (min-width: 1024px) {

  .title,
  .description,
  .checkbox_and_label label,
  .price {
    font-size: 1.25rem;
  }

  .menuItemExpanded {
    width: 25%;
  }

  .description {
    text-align: justify;
    margin: .5rem;
  }

  .title {
    margin: .5rem;
  }

  img {
    height: 12rem;
    width: 12rem;
    margin: 0;
  }

  .optionsList {}

  .optionRow {
    margin: .5rem;
  }

  .checkbox_and_label input[type="checkbox"] {
    width: 2rem;
    height: 2rem;
    margin: 0;
    margin-right: 3%;
  }

  .footer select {
    width: 15%;
  }

  .footer select,
  .footer button {
    font-size: 1.5rem;
    margin: .5rem;
  }

  .checkoutButton {
    width: auto;
    padding: 0 5%;
    font-size: 1.75rem;
  }
}